$(document).on("turbolinks:load", () => {
  disableBatchActions(true)
  const anonCheckbox = $("#anonymous-checkbox")

  anonCheckbox.on("click", () => {
    if(anonCheckbox.prop("checked")){
      disableContactFields(true)
      clearContactFields()
    } else {
      disableContactFields(false)
    }
  })

  $(".message-checkbox").on("click", () => {
    const selectedCheckboxes = $(".message-checkbox:checked")
    if(selectedCheckboxes.length > 0) {
      disableBatchActions(false)
    } else {
      $("#select-all-messages").text("Select All")
      disableBatchActions(true)
    }
  })

  $("#select-all-messages").on("click", () => {
    const checkboxes = $(".message-checkbox")
    if(checkboxes.prop("checked")){
      $("#select-all-messages").text("Select All")
      checkboxes.prop("checked", false)
      disableBatchActions(true)
    } else {
      $("#select-all-messages").text("Deselect All")
      checkboxes.prop("checked", true)
      disableBatchActions(false)
    }
  })

  function disableBatchActions(boolValue = true) {
    $("#submit-selected-archive").attr("disabled", boolValue)
    $("#submit-selected-delete").attr("disabled", boolValue)
  }

  function disableContactFields(boolValue = true) {
    $("#message-name").prop("disabled", boolValue)
    $("#message-email").prop("disabled", boolValue)
    changeDisabledOpacity(boolValue)
  }

  function changeDisabledOpacity(disabledBool) {
    if (disabledBool) {
      $("#message-name").fadeTo('fast', 0.35)
      $("#message-email").fadeTo('fast', 0.35)
    } else {
      $("#message-name").fadeTo('fast', 1)
      $("#message-email").fadeTo('fast', 1)
    }

  }

  function clearContactFields() {
    $("#message-name").val(null)
    $("#message-email").val(null)
  }
})
