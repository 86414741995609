$(document).on("turbolinks:load", () => {
  const toggle = $('.menu-toggle')

  $(toggle).click(() => {
    $(toggle).toggleClass('active')
    $('.side-navigation').toggleClass('active')
  })


  $(document).keydown(e => {
    if(e.keyCode == 27) {
      $('.menu-toggle').removeClass('active')
      $('.side-navigation').removeClass('active')
    }
  })
})
